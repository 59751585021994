<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			<el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small" @submit.native.prevent>
				<el-form-item label="名称" label-width="50px" prop="keyword">
					<el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable
						style="width: 230px" @keyup.enter.native="query"/>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
					<el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			<el-table v-loading="loading" size="small" :data="list" element-loading-text="Loading" highlight-current-row
				style="width: 100%">
				<el-table-column label="ID" width="80" align="center">
					<template slot-scope="scope">
						{{ scope.row.id }}
					</template>
				</el-table-column>
				<el-table-column label="标题" min-width="110" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.title }}</span>
					</template>
				</el-table-column>
				<el-table-column label="颜色" min-width="110" align="center">
					<template slot-scope="scope">
						<el-tag
							:color="scope.row.color"
							size="medium"
							effect="light"
							style="color: #fff;"
							>
							颜色
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="内容" min-width="110" align="center" :show-overflow-tooltip="true">
					<template slot-scope="scope">
						{{ scope.row.content }}
					</template>
				</el-table-column>
				<el-table-column label="状态" min-width="60" align="center">
					<template slot-scope="scope">
						<el-tag v-if="scope.row.status === 1" size="mini" type="success">正常</el-tag>
						<el-tag v-if="scope.row.status === 2" size="mini" type="danger">停用</el-tag>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="160">
					<template slot-scope="scope">
						<i class="el-icon-time" />
						<span>{{ scope.row.utime }}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center">
					<template slot-scope="scope">
						<el-tooltip class="item" effect="dark" content="编辑" placement="top">
							<el-button type="success" icon="el-icon-edit" size="mini" circle
								@click="handleEdit(scope.row)"></el-button>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" content="删除" placement="top">
							<el-button type="danger" icon="el-icon-delete" size="mini" circle
								@click="handleDelete(scope.row.id)"></el-button>
						</el-tooltip>
					</template>
				</el-table-column>
			</el-table>
			<pagination :total="total" auto-scroll :page.sync="queryForm.page" :limit.sync="queryForm.limit"
				@pagination="query" />
		</el-card>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" width="600px" :close-on-click-modal="false" append-to-body :title="title">
			<el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
				<el-form-item label="标题" prop="title">
					<el-input v-model="form.title" placeholder="请输入消息标题" type="text" clearable />
				</el-form-item>
				<el-form-item label="颜色" prop="color">
					<el-color-picker
					  v-model="form.color"
					  show-alpha
					  :predefine="predefineColors">
					</el-color-picker>
				</el-form-item>
				<el-form-item label="内容" prop="content">
					<el-input v-model="form.content" placeholder="请输入消息内容" type="textarea" clearable rows="3" />
				</el-form-item>
				<el-form-item label="跳转类型" prop="type">
					<el-select v-model="form.type" placeholder="请选择跳转类型" size="medium">
						<el-option v-for="(item,index) in types" :key="index" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="文章ID" prop="value" v-if="form.type == 1">
					<el-input v-model="form.value" placeholder="请输入文章ID" type="number" clearable @focus="selArticle" />
				</el-form-item>
				<el-form-item label="状态">
					<el-radio-group v-model="form.status">
						<el-radio :label="1">正常</el-radio>
						<el-radio :label="2">停用</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="排序" prop="sort">
					<el-input v-model="form.sort" placeholder="请输入排序" type="number" clearable />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="mini" @click="cancel">取 消</el-button>
				<el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 选择用户 -->
		<el-dialog :visible.sync="openArticle" width="800px" :close-on-click-modal="false" append-to-body :title="title">
			<el-table
			  :data="articleData"
			  style="width: 100%">
			  <el-table-column
			  label="ID"
			  align="center"
			  prop="id">
			  </el-table-column>
			  <el-table-column
			  prop="url"
			  align="center"
			  label="封面">
			  <template slot-scope="scope">
			   <img :src="scope.row.url" style="width: 30px;height: 30px;cursor: pointer;" @click="viewImage(scope.row,'url')" v-if="scope.row.url">
			   <img src="@/assets/images/empty.png" style="width: 30px;height: 30px;cursor: pointer;" v-else>
			  </template>
			  </el-table-column>
			  <el-table-column
			  label="标题"
			  align="center"
			  prop="title">
			  </el-table-column>
			  <el-table-column
			  label="作者"
			  align="center"
			  prop="anthor">
			  </el-table-column>
			  <el-table-column
			  width="200"
			  align="right">
			  <template slot="header" slot-scope="scope">
				<el-input
				v-model="page.keyword"
				size="mini"
				placeholder="输入关键字搜索">
				  <el-button slot="append" icon="el-icon-search" size="mini" @click="getArticle"></el-button>
				</el-input>
			  </template>
			  <template slot-scope="scope">
				<el-button
				type="danger"
				size="mini"
				@click="handleSel(scope.$index, scope.row)">选择</el-button>
			  </template>
			  </el-table-column>
			</el-table>
			<!-- 分页 -->
			<span slot="footer" class="dialog-footer">
			  <div class="px-2" style="flex: 1;">
			   <pagination
				 :total="page.total"
				 auto-scroll
				 :page.sync="page.page"
				 :limit.sync="page.size"
				 @pagination="getArticle"
			   />
			  </div>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject: ['app'],
		mixins: [common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'notice',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				form: {
					title: '',
					content: '',
					type: 0,
					value: '',
					status: 1,
					sort: 100,
					color:'#18191C',
				},
				rules: {
					title: [{
						required: true,
						message: '请输入消息标题',
						trigger: 'blur'
					}],
					// content: [{
					// 	required: true,
					// 	message: '请填写消息内容',
					// 	trigger: ['blur', 'change']
					// }],
				},
				types:[
					{id:0,name:'不跳转'},
					{id:1,name:'文章'},
				],
				predefineColors: [
				  '#18191C',
				  '#FF2424',
				  '#ffd700',
				  '#90ee90',
				  '#00ced1',
				  '#1e90ff',
				  '#c71585',
				  'rgba(255, 69, 0, 0.68)',
				  'rgb(255, 120, 0)',
				  'hsv(51, 100, 98)',
				  'hsva(120, 40, 94, 0.5)',
				  'hsl(181, 100%, 37%)',
				  'hsla(209, 100%, 56%, 0.73)',
				  '#c7158577'
				],
				page: {
					page: 1,
					limit: 10,
					total:0,
					keyword: '',
				},
				openArticle:false,
				articleData:[],
			}
		},
		mounted() {

		},
		methods: {
			reset() {
				this.form = {
					title: '',
					content: '',
					type: 0,
					value: '',
					status: 1,
					sort: 100,
					color:'#18191C',
				}
				this.resetForm('form')
			},
			selArticle(){
				 this.getArticle()
				 this.$nextTick(()=>{
				   this.title = '文章列表'
				   this.openArticle = true
				 })
			},
			getArticle(){
				this.axios.get('/manage/article/list',{
				  params: {
					page:this.page.page,
					limit:this.page.limit,
					keyword:this.page.keyword,
				  }
				}).then(res=>{
				  this.page.total = res.total
				  this.articleData = res.data
				})
			},
			handleSel(index,item){
				this.form.value = item.id
				this.openArticle = false
				this.title = '新增'
			}
		}
	}
</script>

<style>
</style>